























































import Vue from "vue"

import HeroVariant1 from "@/components/HeroVariant1.vue"
import SectionSplit from "@/components/SectionSplit.vue"

export default Vue.extend({
  components: {
    HeroVariant1,
    SectionSplit
  }
})

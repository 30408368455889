























import Vue from "vue"

export default Vue.extend({
  props: {
    imgSrc: {
      type: String,
      required: true
    },
    disableBorderTop: {
      type: Boolean,
      default: false
    },
    inverted: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classObject(): Record<string, boolean> {
      return {
        "section-split--disable-border-top": this.disableBorderTop,
        "section-split--inverted": this.inverted
      }
    },

    imgStyleObject(): Record<string, string> {
      return {
        backgroundImage: `url("${this.imgSrc}")`
      }
    }
  }
})

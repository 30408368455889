
















import Vue from "vue"

export default Vue.extend({
  props: {
    imgSrc: {
      type: String,
      required: true
    }
  },

  computed: {
    imgStyleObject(): Record<string, string> {
      return {
        backgroundImage: `url("${this.imgSrc}")`
      }
    }
  }
})
